<template>
  <span>
    {{amount}}
  </span>
</template>

<script>
export default {
  inheritAttrs: false,
  computed: {
    amount () {
      return this.value ? this.formatCurrency(this.value[0], this.value[1]) : null
    }
  },
  props: {
    value: {
      type: Array
    }
  }
}
</script>
